<template>
  <div>
    <div
        v-for="(data, typeEvent) in actionObj.data"
        :key = typeEvent
        class="pl-2 codeLineDashedLeft " >

      <h4 class="card-title" >{{ text[typeEvent] }}</h4>
      <div v-for="(action, index) in data.actions"
           :key = index
           class="pl-2 codeLineDashedLeft " >
        <RenderAction
            :tagData = tagData
            :newAction = false
            :action = action
            :actionIndex = index
            :settingConstructor = settingConstructor
            :basePropElems = basePropElems
            @deleteAction="deleteAction"
            :returnData = "{ typeEvent }"
            :parent = "typeEvent"
        />
      </div>
<!--      <h4 class="card-title font-size-14 mb-3 mt-3">И</h4>-->
      <div class="pl-2 codeLineDashedLeft " >
      <RenderAction
          :tagData = tagData
          :newAction = true
          :settingConstructor = settingConstructor
          @addAction="addAction"
          :basePropElems = basePropElems
          :returnData = "{ typeEvent }"
          :parent = "typeEvent"
      />
      </div>
    </div>

<!--    <div class="pl-2 codeLineDashedLeft " >-->
<!--      <h4 class="card-title font-size-14 mb-3 mt-3">Успешная валидация</h4>-->
<!--      <RenderAction-->
<!--          v-for="(action, index) in actionObj.data.validationSuccess.actions"-->
<!--          :key = index-->
<!--          :tagData = tagData-->
<!--          :newAction = false-->
<!--          :action = action-->
<!--          :actionIndex = index-->
<!--          :settingConstructor = settingConstructor-->
<!--          @deleteAction="deleteAction"-->
<!--      />-->

<!--      <RenderAction-->
<!--          :tagData = tagData-->
<!--          :newAction = true-->
<!--          :settingConstructor = settingConstructor-->
<!--          @addAction="addAction"-->
<!--      />-->
<!--    </div>-->

<!--    <div class="pl-2 codeLineDashedLeft" >-->
<!--      <h4 class="card-title font-size-14 mb-3 mt-3">Ошибка при отправке данных</h4>-->
<!--      <RenderAction-->
<!--          v-for="(action, index) in actionObj.data.sendError.actions"-->
<!--          :key = index-->
<!--          :tagData = tagData-->
<!--          :newAction = false-->
<!--          :action = action-->
<!--          :actionIndex = index-->
<!--          :settingConstructor = settingConstructor-->
<!--          @deleteAction="deleteAction"-->
<!--      />-->

<!--      <RenderAction-->
<!--          :tagData = tagData-->
<!--          :newAction = true-->
<!--          :settingConstructor = settingConstructor-->
<!--          @addAction="addAction"-->
<!--      />-->
<!--    </div>-->

<!--    <div class="pl-2 mb-4 codeLineDashedLeft" >-->
<!--      <h4 class="card-title font-size-14 mb-3 mt-3">Успешная отправка данных</h4>-->
<!--      <RenderAction-->
<!--          v-for="(action, index) in actionObj.data.sendSuccess.actions"-->
<!--          :key = index-->
<!--          :tagData = tagData-->
<!--          :newAction = false-->
<!--          :action = action-->
<!--          :actionIndex = index-->
<!--          :settingConstructor = settingConstructor-->
<!--          @deleteAction="deleteAction"-->
<!--      />-->

<!--      <RenderAction-->
<!--          :tagData = tagData-->
<!--          :newAction = true-->
<!--          :settingConstructor = settingConstructor-->
<!--          @addAction="addAction"-->
<!--      />-->
<!--    </div>-->

  </div>
</template>

<script>
// import RenderAction from '../_renderAction'
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "SendFormWidget",
  components: {
    RenderAction: () => import('../_renderAction.vue')
  },
  props: ["tagData", "settingConstructor", "basePropElems", "actionObj"],
  data() {
    return {
      text: {
        validationError: this.$t('Constructor.PropWidgets.SendFormWidget.Texts.ValidationError'),
        validationSuccess: this.$t('Constructor.PropWidgets.SendFormWidget.Texts.ValidationSuccess'),
        sendError: this.$t('Constructor.PropWidgets.SendFormWidget.Texts.SendError'),
        sendSuccess: this.$t('Constructor.PropWidgets.SendFormWidget.Texts.SendSuccess')
      },
      widgetDataPattern: {
        validationError: {
          actions: [
            // {
            //   "type": "autoValidateForm",
            //   "data": {}
            // }
          ]
        },
        validationSuccess: {
          actions: []
        },
        sendError: {
          actions: []
        },
        sendSuccess: {
          actions: []
        }
      }
    }
  },
  created() {
    if (this.actionObj.data === null || !Object.keys(this.actionObj.data).length) {
      this.actionObj.data = this.widgetDataPattern
    }
  },
  methods: {
    ...HistoryHelper,
    addAction: function(actionObj, { typeEvent }) {
      this.HistoryPush(this.actionObj.data[typeEvent], 'actions', actionObj)
      // this.actionObj.data[typeEvent].actions.push(actionObj)
    },
    deleteAction: function(actionIndex, { typeEvent }) {
      this.HistoryDelete(this.actionObj.data[typeEvent].actions, actionIndex)
      // this.$delete(this.actionObj.data[typeEvent].actions, actionIndex)
    }
  }
}
</script>
